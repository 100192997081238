import React from 'react';
import Nav from '../components/Nav'
import HeaderDark from '../components/HeaderDark'
import '../styles/success-page.css'
import '../styles/circles.css'
import Footer from '../components/Footer';
import RocketMark from '../img/rocketmark-white.svg'
import { Helmet } from "react-helmet"
import Session321 from '../components/Session321'
const SuccessPage = () => {
    return(
        <div id="success_page">
            <Helmet>
                <title>Thank you!</title>
                <meta name="description" content="Thank you for your request. A representative will be in touch soon."></meta>
                <meta name="robots" content="noindex" />
                <Session321 />
            </Helmet>
            <Nav />
            <HeaderDark />

                <div className="content">
                    <h1>Launch Successful</h1>
                    <p>We have received your submission.</p>
                    <p>An agent will call soon at the number you provided to confirm your business details and review your coverage options.</p>
                    <h2>In a hurry?</h2>
                    <p>Give us a call at: <span className="upsize subtle-green call-conversion"><strong><a href="tel:+1-234-230-6321">(234) 230-6321</a></strong></span> ...and an agent will process your request right away.</p>
                </div>
                <div className="circle-container">
                        <div className="circle" id="first_circle">
                            <div className="circle">
                                <div className="circle">
                                    <div className="circle">
                                        <div className="circle">
                                            <div className="circle">
                                                <div id="rocketmark"><img src={RocketMark} alt="insurance321 brand mark" /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            <Footer />
        </div>
    )
}

 
export default SuccessPage;